import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import Obfuscate from "react-obfuscate";
import SEO from "./../components/seo"
import Layout from "../components/layout"

class ConditionsVente extends React.Component {
    render() {   
        return(
            <>
            <SEO
              title={`Conditions générales de vente`}
              description="Conditions générales de vente du site France-cadre"/>
                <Layout banner={false} location={this.props.location}>
                
                    <h1 style={{ marginTop: "6rem"}}>Conditions générales de vente</h1>

                    <h2>Général</h2>
                    <span style={{textDecoration: 'underline'}}>Adresse du siège social</span><br/>
                    <span>Les Professionnels du Cadre</span>
                    <br />
                    <span>Route de Pelousey</span>
                    <br />
                    <span>25115 Pouilley Les Vignes</span>
                    <br />
                    <span>Siret : 409 786 290 00091</span>
                    <br />
                    <span>Numéro de TVA : FR88 409 786 290</span>
                    <br />
                    <br />
                    <span style={{textDecoration: 'underline'}}>Coordonnées bancaires</span>
                    <br />
                    <span>RIB : 10278 01004 00059896114 27 (crédit mutuel de STRASBOURG)</span>
                    <br />
                    <span>IBAN : FR76 1027 8010 0400 0598 9611 427 BIC : CMCIFR2A (crédit mutuel de STRASBOURG)</span>
                    <br />
                    <br />
                    <span style={{textDecoration: 'underline'}}>Nom et fonction du contact</span>
                    <br />
                    <span>DEVAUX OLIVIER directeur commercial (France et Europe)</span>
                    <br />
                    <br />
                    <span style={{textDecoration: 'underline'}}>Numéro de téléphone</span>
                    <br />
                    <span>03.89.06.00.93 choix 01 joignable du lundi au vendredi de 9h à 18h et le samedi de 9h à 12h</span>
                    <br />
                    <br />
                    <span style={{textDecoration: 'underline'}}>Adresse mail</span>
                    <br />
                    <span><Obfuscate email="contact@producadre.fr" /></span>

                    <br />
                    <h2>Préambule</h2>                
                    <p>
                        Le site www.france-cadre.fr est la propriété de la société ‘LES PROFESSIONNELS DU CADRE’ en sa totalité, 
                        ainsi que l’ensemble des droits y afférant. Toute reproduction, intégrale ou partielle, 
                        est systématiquement soumise à l’autorisation du propriétaire. Toutefois, 
                        les liaisons de type hypertexte vers le site sont autorisées sans demande spécifique.
                    </p>
                    <p>
                        Le client est un internaute qui s’est connecté sur le site www.france-cadre.fr et qui désire passer commande d’un produit. 
                        Il déclare avoir la capacité de conclure le présent contrat, dont les conditions générales de vente sont présentées ci-après, 
                        c‘est-à-dire avoir la majorité légale et ne pas être sous tutelle ou curatelle.
                    </p>
                    <p>
                        La réglementation applicable est celle de la vente à distance en vigueur en France. Le Client déclare avoir pris connaissance 
                        des stipulations suivantes avant de passer commande des Produits. En choisissant d’acheter les Produits, le Client accepte 
                        expressément et irrévocablement les termes ci-après énoncés.   
                    </p>
                    

                    <br />
                    <h2>Photos Produits</h2>
                    <div>
                        <p>
                            Les photographies illustrant les produits, n‘entrent pas dans le champ contractuel. Si des erreurs s‘y sont introduites, 
                            en aucun cas, la responsabilité de www.france-cadre.fr ne pourra être engagée.  
                        </p>
                    </div>

                    <br />
                    <h2>Commande</h2>
                    <p>
                        Le Client remplit son bon de commande et le transmet en ligne. Les systèmes d’enregistrement automatique sont considérés comme 
                        valant de preuve de la nature, du contenu, et de la date de commande. www.france-cadre.fr confirme l’acceptation de sa commande 
                        au client à l’adresse mail que celui-ci aura communiquée. La commande est enregistrée et pris en compte par la Société 
                        à réception de son règlement.  
                    </p>

                    <br />
                    <h2>Confirmation de commande</h2>
                    <p>
                        A compter de l’enregistrement de la commande, la Société adresse au Client un e-mail de confirmation. En conservant cet e-mail 
                        ou en l’imprimant, le Client détient une preuve de sa commande, ce que la Société lui recommande. A compter de l’enregistrement 
                        de la commande, cette dernière est considérée comme irrévocable et ne peut être remise en cause que dans les cas limitativement 
                        énumérés ci-après.  
                    </p>

                    <br />
                    <h2>Rupture de stock</h2>
                    <p>Toutes les commandes seront honorées dans la limite des stocks disponibles. Si un produit vient à manquer, pour quelque raison 
                        que ce soit (rupture momentanée de stock, arrêt d’un produit par un fabricant…) nous nous réservons le droit en accord avec l’acheteur&nbsp;:
                    </p>
                    <ul>
                        <li style={{listStyleType: 'disc', marginLeft: '1.6rem'}}>
                            De l’informer d’un délai plus long et de convenir d’une date de livraison, d’effectuer une livraison partielle avec 
                            l’accord de l’acheteur, les frais d’expédition seront à la charge du vendeur.
                        </li>
                        <li style={{listStyleType: 'disc', marginLeft: '1.6rem'}}>
                            De livrer un produit similaire toujours avec l’accord de l’acheteur
                        </li>
                        <li style={{listStyleType: 'disc', marginLeft: '1.6rem'}}>
                            De rembourser sa commande si aucun accord n’est trouvé. Le remboursement sera effectué du montant 
                            de la commande sans aucun préjudice dans un délai d’un mois maximum par chèque bancaire.
                        </li>
                    </ul>

                    <br />
                    <h2>Déréglages et bugs informatiques</h2>
                    <p>
                        Nos sites sont régulièrement mis à jour par nos programmeurs indépendants. Certains modules pourraient momentanément dysfonctionner. 
                        Notre société ne pourrait être tenue responsable. Si l’erreur constatée est supérieure à 50%, ceci mettant en évidence un déréglage 
                        manifeste et évident de la commande réellement valorisée, nous bénéficions de la décision d‘annuler celle-ci sans recours et 
                        procéderons au remboursement sous 72h. 
                    </p>

                    <br/>
                    <h2>Délais de livraison</h2>
                    <p>
                        Le délai moyen de livraison varie entre 8 jours et 15 jours ouvrés suivant les produits commandés.
                        <br />
                        Pour plus de précisions, vous pouvez contacter OLIVIER DEVAUX au 03.89.06.00.93 choix 01 qui pourra vous donner un délais précis suivant votre demande
                        <br />
                        Les délais de livraison qui pourraient figurer sur les bons de commande ou tout autre document n’ont qu’une valeur indicative.
                        <br />
                        Pour votre information, aucune livraison ne sera possible le samedi (uniquement du lundi au vendredi) donc il faut renseigner une adresse de livraison pour laquelle une personne sera présente en semaine pour la réception du colis. Dans le cas où celui-ci devait être dévoyé à une autre adresse que celle renseignée par vos soins, un forfait de 25€ vous sera facturé pour la livraison à une nouvelle adresse.
                    </p>

                    <br />
                    <h2>Vérification du Produit à la réception</h2>
                    <p>
                        Le Client est tenu de vérifier l’état de l’emballage et la conformité du Produit livré. Toute réclamation sur les vices apparents
                        ou sur la non-conformité du Produit devra être signalée par le Client Nous ne pouvons être tenu responsable lorsqu’un Client a 
                        accepté un colis présentant une détérioration importante de nature à abîmer le produit. Il est de son devoir d’émettre des réserves 
                        et de refuser le colis. Nos colis sont assurés pendant le transport depuis l’expédition jusqu’à la livraison. Lorsque le colis est 
                        accepté par le client, nous n’avons plus aucun recours possible. De ce fait, tout retour de marchandise est à la charge du client en 
                        cas de réclamation.
                        <br />
                        Aucune marchandise ne sera retournée sans notre accord préalable. Aucun Produit ne pourra être échangé avant d’avoir été réexpédié et 
                        réceptionné par la Société en bon état, tel que livré par les soins de la Société, En particulier, le Produit ne devra pas avoir été 
                        descellé.
                    </p>

                    <br />
                    <h2>Vérification du Produit au déballage</h2>
                    <p>
                        Le client ayant reçu un colis ne présentant aucun défaut apparent, mais s’aperçoit au moment du déballage de la commande, 
                        qu’un cadre est abimé ou qu’un verre est brisé(ou autre) , il suffira d’adresser un mail avec photo du produit présentant 
                        le défaut ainsi que le numéro de commande et spécifié quel cadre de la commande est abimé (au cas où il y en a plusieurs 
                        dans la commande) à <span><Obfuscate email="contact@producadre.fr" /></span>. Le S.A.V ne sera pris en compte qu’à réception de ces éléments, 
                        une réponse rapide et efficace sera adressée à l’acheteur avec la marche à suivre. 
                        <br />
                        <br />

                        Pour des raisons de sécurité, le verre d’un cadre cassé suite au transport est automatiquement remplacé par 1 plexi d’épaisseur 2mm (une face 
                        normale, une face antireflet) haute qualité.
                    </p>

                    <br />
                    <h2>IMPORTANT - PRODUIT NON RÉCEPTIONNÉ</h2>
                    <p>
                        Lorsque la commande sera expédiée de nos ateliers, l’acheteur sera informé par mail. Pour information, la durée d‘acheminement 
                        de votre palette ou colis est de 4 à 5 jours ouvrés, passé ce délai, merci de nous prévenir par 
                        mail (<span><Obfuscate email="contact@producadre.fr" /></span>) ou par téléphone au 03 89 06 00 93 pour nous informer du soucis 
                        de la livraison, afin que nos services mettent tout en œuvre la bonne réception des produits commandés. Le transporteur conserve
                        en moyenne un colis non réceptionné durant 10 jours avant de le retourner à l‘expéditeur. Tout colis qui nous sera retourné par
                        le transporteur suite à un manquement de suivis, d’information ou d’absence prolongée de l’acheteur sera réexpédié à ses frais, après
                        avoir réglé par CB, Paypal, virement ou chèque un montant forfaitaire de 50 € correspondant aux frais de réexpédition et de logistique.
                    </p>

                    <br />

                    <br />
                    <h2>Force majeure</h2>
                    <p>
                        La Société dégage sa responsabilité pour tout manquement à ses obligations contractuelles dans l’hypothèse d’une force majeure ou 
                        fortuite, y compris mais sans y être limitées, catastrophes, incendies, grève interne ou externe, défaillance ou pannes internes ou 
                        externe, et d’une manière générale tout événement ne permettant pas la bonne exécution des commandes. 
                    </p>

                    <br />
                    <h2>Licence d’accès au site</h2>
                    <p>
                        Nous vous accordons une licence limitée à l‘accès et à l‘utilisation du site pour votre utilisation personnelle. 
                        En aucun cas vous n‘êtes autorisé à télécharger ou à modifier tout ou partie de ce site sans notre autorisation écrite et expresse. 
                        Cette licence ne vous permet en aucun cas d‘utiliser, pour la vente ou pour toute autre utilisation commerciale ce site ou son contenu 
                        (produits listés, descriptions, prix, téléchargement ou copie des informations pour le compte d‘un autre commerçant, 
                        utilisation de données, logiciels, extraits sonores, graphismes, images, textes, photographies, outils). 
                        Ce site ou toute partie de ce site ne doit en aucun cas être reproduit, copié, vendu ou exploité pour des raisons commerciales 
                        sans notre autorisation expresse et écrite. Vous ne devez pas utiliser des techniques permettant de copier une marque, 
                        un logo ou toute autre information (notamment images, texte, maquettes) dont nous sommes propriétaires sans notre accord express et écrit. 
                        Vous ne devez pas utiliser les méta tags ou tout autre texte « caché » contenant notre nom, notre marque ou celui des sociétés de notre 
                        groupe sans notre accord express et écrit. Toute utilisation non autorisée pourra faire l’objet de poursuites.
                        <br />
                        Nous vous autorisons, à titre non exclusif et révocable, à créer un lien hypertexte pointant sur la page d‘accueil du Site à la condition 
                        que ce lien ne puisse Nous créer ou créer à l‘encontre des sociétés de notre groupe, ou à nos produits ou services, un caractère mensonger, 
                        faux, péjoratif ou pouvant nous porter préjudice. En aucun cas la création de ce lien hypertexte ne pourra engager notre responsabilité, 
                        à quelque titre que ce soit, sur le contenu de votre site. Toute utilisation dans votre lien de notre logo, de notre marque ou de nos 
                        graphismes nécessite notre autorisation expresse et écrite.
                    </p>

                    <br />
                    <h2>Intégralité</h2>
                    <p>
                        Les présentes conditions générales et les rubriques afférentes représentent l’intégralité des droits et obligations des parties 
                        concernant les commandes de Produits sur Internet. Aucune condition générale ou spécifique communiquée par le Client ne pourra 
                        s’intégrer aux présentes conditions générales de vente.  
                    </p>

                    <br />
                    <h2>Litige</h2>
                    <p>
                        En cas de difficulté relative à l’interprétation ou à l’application des présentes conditions de ventes, les parties essaieront 
                        dans toute la mesure du possible de résoudre leur litige à l’amiable. En cas d’échec de ces tentatives, toutes contestations 
                        auxquelles peuvent donner lieu les présentes conditions de ventes devront être portées même en cas de pluralités des défendeurs 
                        ou d’appel de garantie devant un Tribunal de commerce, d’instance ou de grande instance.
                        <br />
                        Conformément à l‘article L. 612-1 du code de la consommation, vous pouvez recourir gratuitement au service de médiation MEDICYS 
                        dont nous relevons par voie électronique: https://app.medicys.fr/?proId=0ca88e8d-4abe-4b17-8577-0d29dac1c5cf ou par voie postale: 
                        MEDICYS – 73 Boulevard de Clichy – 75009 PARIS
                    </p>
                    <br /> 
                </Layout>


            </>
        )
    }
}

ConditionsVente.propTypes = {
    identifiants: PropTypes.object,
    location: PropTypes.object
  }
  
  
  function mapStateToProps(state) {
    return {
      identifiants: state.identifiants,
    }
  }
  
export default connect(mapStateToProps)(ConditionsVente)